import React, { useState, useEffect, useCallback } from 'react';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import background from '../../assets/Photos/Slider/Background-2.png';
import tiklayiniz from '../../assets/Photos/Slider/slider_tiklayiniz.png';
import { Link } from 'react-router-dom';

const slides = [
    { id: 0, src: tiklayiniz, alt: "Slide 1" }
];

const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const slideInterval = 5000; // 3 saniye

    const nextSlide = useCallback(() => {
        setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    }, []);

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    };

    useEffect(() => {
        const interval = setInterval(nextSlide, slideInterval);
        return () => clearInterval(interval);
    }, [nextSlide]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const goToSlide = (slideIndex) => {
        setCurrentSlide(slideIndex);
    };

    return (
        <div id="default-carousel" className="relative w-full" data-carousel="slide">
            <div
                className="relative overflow-hidden rounded-lg"
                style={{
                    aspectRatio: isMobile ? '16/18' : '16/6', // Ekran boyutuna göre aspect ratio
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                {slides.map((slide, index) => (
                    <div
                        key={slide.id}
                        className={`absolute inset-0 transition-opacity duration-700 ease-in-out ${index === currentSlide ? 'opacity-100' : 'opacity-0'}`}
                    >
                        <Link to={`https://www.shopier.com/ShowProductNew/storefront.php?shop=3NSuAritmaVePompaSistemleri&sid=b2VSQ3JmZkNXS1BiTFJRVTBfLTFfIF8g`} target='_blank'>
                            <img
                                src={slide.src}
                                className="block w-full h-full object-contain"
                                alt={slide.alt}
                            />
                        </Link>
                    </div>
                ))}
            </div>
            <div className="absolute z-10 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
                {slides.map((slide, index) => (
                    <button
                        key={slide.id}
                        type="button"
                        className={`w-3 h-3 rounded-full ${index === currentSlide ? 'bg-white' : 'bg-gray-400'}`}
                        aria-current={index === currentSlide}
                        aria-label={`Slide ${index + 1}`}
                        onClick={() => goToSlide(index)}
                    ></button>
                ))}
            </div>
            <button
                type="button"
                className="absolute top-0 left-0 z-10 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                onClick={prevSlide}
            >
                <FaArrowLeft />
                <span className="sr-only">Previous</span>
            </button>
            <button
                type="button"
                className="absolute top-0 right-0 z-10 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                onClick={nextSlide}
            >
                <FaArrowRight />
                <span className="sr-only">Next</span>
            </button>
        </div>
    );
}

export default Slider;