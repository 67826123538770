export const menuItemsData = [
    {
        title: 'ANA SAYFA',
        url: '/',

    },
    {
        title: 'HAKKIMIZDA',
        url: '/hakkimizda',
    },
    {
        title: 'İLETİŞİM',
        url: '/iletisim',
    }
];