import Slider from '../../components/Main/Slider';

const AnaSayfaMainSection = () => {
    return (
        <div className='flex max-h-screen '>
            <Slider/>
        </div>
    )
}

export default AnaSayfaMainSection;