export const IletisimData = [
    {
        title: "Adres",
        text: "Örnekköy, Tepetarla Cd. No:32/B, 34825 Beykoz/İstanbul",
        which_icon: "1",
    },
    {
        title: "Telefon Numarası",
        text: "+90 535 979 61 22",
        which_icon: "2",
    },
    {
        title: "Mail",
        text: "info@3naritmapompa.com",
        which_icon: "3",
    },
];