import { Link } from "react-router-dom";
import Logo from "../../assets/Photos/Company/logoIcon.png"
import MomentumLogo from "../../assets/Photos/Company/MomentumLogo.png"
import { menuItemsData } from "../../assets/menunItemsData";
const Footer = () => {
    return (
        <div className="p-4 bg-white md:p-8 lg:p-10 border-t-2">
            <div className="flex flex-col space-y-2 items-center justify-center mx-auto max-w-screen-xl text-center">
                <Link to="/" className="flex flex-row space-x-5 items-center justify-center">
                    <div className="flex justify-center items-center text-lg font-medium text-gray-900">
                        <img className="mr-2 h-8" viewBox="0 0 33 33" fill="none" alt="logo" src={Logo}></img>
                    </div>
                    <div className="flex justify-center items-center text-lg font-medium text-gray-900">
                        <img className="mr-2 h-8" viewBox="0 0 33 33" fill="none" alt="momentum logo" src={MomentumLogo}></img>
                    </div>
                </Link>
                <ul className="flex flex-wrap justify-center items-center text-center mb-6 text-gray-900">
                    {menuItemsData.map((menu, index) => {
                        return (
                            <li key={index}>
                                <Link to={menu.url} className="mr-4 hover:underline md:mr-6">
                                    {menu.title}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                <span className="text-sm text-gray-500 sm:text-center">©<Link to="/" className="hover:underline">3N Su Arıtma Ve Pompa Sistemleri</Link>. Tüm hakları saklıdır.</span>
            </div>
        </div>
    )
}

export default Footer;