import {
  RouterProvider,
} from "react-router-dom";
import { router } from "./router/Route";
import WhatsappContact from "./components/WhatsappContact";

function App() {
  return (
    <>
      <WhatsappContact />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
