import Footer from "../../components/Footer";
import Header from "../../components/Header";
import AnaSayfaMainSection from "./AnaSayfaMainSection";

const Main = () => {
    return (
        <div>
            <Header />
            <AnaSayfaMainSection />
            <Footer />
        </div>
    )
}

export default Main;