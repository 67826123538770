
import { createBrowserRouter } from "react-router-dom";
import Main from "../pages/Main";
import About from "../pages/About";
import Contact from "../pages/Contact";
import NotFound from "../pages/NotFound";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <NotFound />
  },
  {
    path: "/hakkimizda",
    element: <About />,
    errorElement: <NotFound />
  },
  {
    path: "/iletisim",
    element: <Contact />,
    errorElement: <NotFound />
  }
]);
