//import Logo from "../Icons/Logo.png"
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import BurgerMenu from "./BurgerMenu";
import Logo from "../../assets/Photos/Company/logoIcon.png"
const Header = () => {

    return (
        <div className="pb-0 bg-white border-b-2">
            <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                    <Link to={"/"} className="flex items-center">
                        <img src={Logo} alt="logo" className="mr-3 h-9 sm:h-20" />
                        <span className="block self-center text-sm md:text-xl font-semibold whitespace-nowrap">3N Su Arıtma Ve Pompa Sistemleri</span>
                    </Link>
                    <BurgerMenu />
                    <Navbar />
                </div>
            </nav>
        </div>
    )
}

export default Header;