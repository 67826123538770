const Map = () => {
    return (
        <div className="shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:w-5/12 lg:h-full lg:px-6 lg:mt-12">
            
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6009.09276312634!2d29.144404595499072!3d41.1444275353267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x409fdfa935d6ce31%3A0x7fb2bf3534889254!2s3N%20Su%20Ar%C4%B1tma%20%26%20Pompa%20Sistemleri!5e0!3m2!1str!2str!4v1722201964763!5m2!1str!2str"
                className="w-[350px] h-[300px] lg:w-full lg:h-[400px]"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                
                referrerPolicy="no-referrer-when-downgrade"
                title="a">
            </iframe>
        </div>
    )
}

export default Map;